
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ItemIconCard: () => import('../Item/ItemIconCard.vue')
  }
})
export default class CharacterEquippedItems extends Vue {
  @Prop() data
}
